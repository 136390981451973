var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ErrorContext = exports.TypedError = exports.ArgumentTypeError = exports.PositionalArgsError = void 0;
class PositionalArgsError extends Error {
  constructor() {
    super("Contract method calls expect named arguments wrapped in object, e.g. { argName1: argValue1, argName2: argValue2 }");
  }
}
exports.PositionalArgsError = PositionalArgsError;
class ArgumentTypeError extends Error {
  constructor(argName, argType, argValue) {
    super(`Expected ${argType} for '${argName}' argument, but got '${JSON.stringify(argValue)}'`);
  }
}
exports.ArgumentTypeError = ArgumentTypeError;
class TypedError extends Error {
  constructor(message, type, context) {
    super(message);
    this.type = type || "UntypedError";
    this.context = context;
  }
}
exports.TypedError = TypedError;
class ErrorContext {
  constructor(transactionHash) {
    this.transactionHash = transactionHash;
  }
}
exports.ErrorContext = ErrorContext;
export default exports;